import { merge } from "lodash";

import { envName, host, runtime, runtimeVersion, version } from "~/env";

import { LogLevel } from ".";

export abstract class BaseLogger {
  protected mixin = {};
  protected level = LogLevel.info;

  constructor(level = LogLevel.info) {
    this.level = level;
    this.set({
      env: envName,
      host,
      runtime,
      runtimeVersion,
      version,
    });
  }

  public set(data: Record<string, unknown>) {
    this.mixin = merge(this.mixin, data);
  }
}
