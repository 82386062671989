import { LogLevel } from ".";
import { BaseLogger } from "./BaseLogger";
import { type Logger } from "./Logger";

export abstract class JSONLogger extends BaseLogger implements Logger {
  constructor(level = LogLevel.info) {
    super(level);
  }

  async flush() {}
  abstract emit(data: Record<string, unknown>): void;

  public debug(message: string, data?: Record<string, unknown> | undefined) {
    if (this.level > LogLevel.debug) return;
    this.emit({
      level: "debug",
      message,
      ...this.mixin,
      ...(data ?? {}),
    });
  }

  public info(message: string, data?: Record<string, unknown> | undefined) {
    if (this.level > LogLevel.info) return;
    this.emit({
      level: "info",
      message,
      ...this.mixin,
      ...(data ?? {}),
    });
  }

  public warn(message: string, data?: Record<string, unknown> | undefined) {
    if (this.level > LogLevel.warn) return;
    this.emit({
      level: "warn",
      message,
      ...this.mixin,
      ...(data ?? {}),
    });
  }

  public error(message: string, data?: Record<string, unknown> | undefined) {
    if (this.level > LogLevel.error) return;
    this.emit({
      level: "error",
      message,
      ...this.mixin,
      ...(data ?? {}),
    });
  }
}
