import { type Logger } from "./Logger";

export class NoopLogger implements Logger {
  constructor() {}
  public set() {}
  async flush() {}
  emit() {}
  info() {}
  debug() {}
  error() {}
  warn() {}
}
