/* eslint-disable no-console */
import { LogLevel } from "~/utils/logger";

import { BaseLogger } from "./BaseLogger";
import { type Logger } from "./Logger";

export class ConsoleLogger extends BaseLogger implements Logger {
  private maybeObject(
    data?: Record<string, unknown> | undefined,
  ): (string | Record<string, unknown>)[] {
    const obj = {
      ...this.mixin,
      ...(data ?? {}),
    };

    if (Object.keys(obj).length) {
      return [obj];
    } else {
      return [];
    }
  }

  public debug(message: string, data?: Record<string, unknown> | undefined) {
    if (this.level > LogLevel.debug) return;
    console.debug(message, ...this.maybeObject(data));
  }

  public info(message: string, data?: Record<string, unknown> | undefined) {
    if (this.level > LogLevel.info) return;
    console.info(message, ...this.maybeObject(data));
  }

  public warn(message: string, data?: Record<string, unknown> | undefined) {
    if (this.level > LogLevel.warn) return;
    console.warn(message, ...this.maybeObject(data));
  }

  public error(message: string, data?: Record<string, unknown> | undefined) {
    if (this.level > LogLevel.error) return;
    console.error(message, ...this.maybeObject(data));
  }

  public override set(data: Record<string, unknown>) {
    this.mixin = { ...this.mixin, ...data };
  }

  async flush() {
    return await Promise.resolve();
  }
}
