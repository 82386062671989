import { Logtail as logtail } from "@logtail/edge";
import { type Context } from "@logtail/types";
import { waitUntil } from "@vercel/functions";
import { merge } from "lodash";

import { LogLevel } from ".";
import { JSONLogger } from "./JSONLogger";

export class LogtailJSONLogger extends JSONLogger {
  private logTail: logtail;
  constructor(
    level = LogLevel.info,
    token: string,
    private consoleLevel?: LogLevel,
  ) {
    super(level);
    this.logTail = new logtail(token, {
      warnAboutMissingExecutionContext: false,
      batchInterval: 1,
      retryCount: 2,
      syncMax: 20,
      batchSize: 1,
      sendLogsToConsoleOutput: false,
    });
  }

  async flush() {
    await this.logTail.flush();
  }

  emit(data: Record<string, unknown>): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let { message, level, ...extraData } = data;
    const context = merge({}, this.mixin, extraData) as Context;

    switch (level) {
      case "info":
        void this.logTail.info(
          (message as string) ?? (level as string) ?? "",
          context as Context,
        );
        waitUntil(this.flush());
        break;
      case "warn":
        void this.logTail.warn(
          (message as string) ?? (level as string) ?? "",
          context as Context,
        );
        waitUntil(this.flush());
        break;
      case "error":
        void this.logTail.error(
          (message as string) ?? (level as string) ?? "",
          context as Context,
        );
        waitUntil(this.flush());
        break;

      default:
      case "debug":
        void this.logTail.debug(
          (message as string) ?? (level as string) ?? "",
          context as Context,
        );
        waitUntil(this.flush());
        break;
    }
  }
}
