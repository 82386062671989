/* eslint-disable no-console */
import { env, isDev, isTest } from "~/env";
import { logError as universalLogError, LogLevel } from "~/utils/logger";
import { type Logger } from "~/utils/logger/Logger";
import { LogtailJSONLogger } from "~/utils/logger/LogtailJSONLogger";

import { ConsoleLogger } from "../utils/logger/ConsoleLogger";

const logLevel = env.NEXT_PUBLIC_LOG_LEVEL
  ? LogLevel[env.NEXT_PUBLIC_LOG_LEVEL.toLowerCase() as keyof typeof LogLevel]
  : isDev
    ? LogLevel.debug
    : LogLevel.info;

export const logger: Logger =
  isDev || isTest || !env.NEXT_PUBLIC_LOGTAIL_TOKEN
    ? new ConsoleLogger(logLevel)
    : new LogtailJSONLogger(logLevel, env.NEXT_PUBLIC_LOGTAIL_TOKEN);

export function logError(
  error: string | number | Error,
  message?: string,
  extraData?: Record<string, unknown>,
): void {
  universalLogError(error, message, extraData, logger);
}
