import { isAxiosError } from "axios";

import { type Logger } from "./Logger";
import { NoopLogger } from "./NoopLogger";

export enum LogLevel {
  debug = 0,
  info = 1,
  warn = 2,
  error = 3,
  off = 100,
}
export function getObjectClassName(
  obj: Record<string, unknown> | Error | undefined | null,
): string {
  if (typeof obj === "undefined") return "undefined";
  if (obj === null) return "null";
  try {
    const name = obj.name;
    if (name) {
      return name as string;
    }
  } catch (e) {}
  if (obj.constructor?.name) {
    return obj.constructor.name;
  }
  if (Object) {
    try {
      // @ts-ignore
      const name = Object.prototype.toString
        .call(obj)
        .match(/^\[object\s(.*)\]$/)[1];
      if (name) {
        return name;
      }
    } catch (e) {
      return "Object";
    }
  }

  return "Object";
}

function convertToError(
  error: string | number | Error,
  message?: string,
): Error {
  if (!(error instanceof Error)) {
    if (typeof error === "object") {
      return new Error(getObjectClassName(error));
    } else {
      return new Error(`${error}` || message || "Error");
    }
  }

  return error;
}

type ErrorWithData = Error & { data: Record<string, unknown> };

export function errorToJson(
  error: Error | Record<string, unknown> | null | unknown,
): Record<string, unknown> {
  const errorType = typeof error;
  if (errorType !== "object") {
    try {
      // Normalise thrown scalar values to an Error instance
      error = new Error(`${error as string}`);
    } catch (e) {
      // in case toString() fails
      error = new Error(errorType);
    }
  }

  return {
    error: {
      ...((error as ErrorWithData).data ?? {}),

      name: getObjectClassName(error as Error),
      message: (error as Error).message || getObjectClassName(error as Error),
      stack: (error as Error).stack,
      path: (error as Error & { path: string }).path,
      cause: (error as Error).cause
        ? errorToJson((error as Error).cause as Error)
        : undefined,

      // @link https://nextjs.org/docs/app/api-reference/file-conventions/error#errordigest
      digest: (error as Error & { digest: string }).digest,
    },
  };
}

export function logError(
  error: string | number | Error,
  message?: string,
  extraData?: Record<string, unknown>,
  loggerInstance: Logger = new NoopLogger(),
): void {
  error = convertToError(error, message);
  extraData = extraData ?? {};

  // Work around "Access denied" errors from Keystone.js initConfig
  if (isKeystoneAccessDeniedError(error)) {
    const originalStack = error.stack;
    error = new (class extends Error {
      override name = "ForbiddenError";
    })(error.message);
    error.stack = originalStack;
  }

  if (isAxiosError(error)) {
    extraData.axios = {
      config: {
        url: error.config?.url,
        method: error.config?.method,
      },
      code: error.code,
      status: error.status,
      request: {
        timeout: error.request?.timeout,
        timeoutErrorMessage: error.request?.timeoutErrorMessage,
        retry: {
          ...(error.request?.["axios-retry"] ?? {}),
        },
      },
      response: {
        status: error.response?.status,
        statusText: error.response?.statusText,
        headers: error.response?.headers,
      },
    };
    error = error.toJSON() as Error;
  }

  try {
    switch (error.name) {
      case "SyntaxError": // possibly a JSON parse error
        if (isJSONSyntaxError(error)) {
          loggerInstance.debug(message ?? `${error.name}: ${error.message}`, {
            ...errorToJson(error),
            ...extraData,
          });
        } else {
          loggerInstance.error(message ?? `${error.name}: ${error.message}`, {
            ...errorToJson(error),
            ...extraData,
          });
        }

        break;

      case "AuthenticationError": // used by Apollo
      case "ValidationError":
      case "ForbiddenError":
        loggerInstance.warn(message ?? `${error.name}: ${error.message}`, {
          ...errorToJson(error),
          ...extraData,
        });
        break;

      default:
        loggerInstance.error(message ?? `${error.name}: ${error.message}`, {
          ...errorToJson(error),
          ...extraData,
        });
    }
  } catch (e) {
    // last resort error logging attempt
    /* eslint-disable no-console */
    console.error(`Error while trying to log an error ${e as string}`);
    console.error(error);
  }
}

function isKeystoneAccessDeniedError(error: Error) {
  return !!(
    error instanceof Error &&
    error.stack &&
    error.stack.match(
      /Error: Access denied[\s]{2,}at \S+\/@keystone-6\/core\/dist\/initConfig/is,
    )
  );
}

function isJSONSyntaxError(error: Error): boolean {
  return !!(error.stack && error.stack.includes("at JSON.parse"));
}
